import React from 'react'
import { graphql } from 'gatsby'

import { Seo as SEO } from '../components/Seo'
import Layout from '../components/layout/Layout'
import Keywords from '../components/Keywords'
const AboutBanner = React.lazy(() => import('../components/home/AboutBanner'))
const VirtualTour = React.lazy(() => import('../components/home/VirtualTour'))
const CapabilitiesSection = React.lazy(() =>
  import('../components/home/CapabilitiesSection')
)
const LatestNews = React.lazy(() => import('../components/home/LatestNews'))

const IndexPage = ({ data }) => {
  const capabilities = data.capabilities.nodes
  const latestNewsData = data.news.nodes

  return (
    <Layout pathname="/">
      {/* <ImageSlider images={images} autoplay/> */}
      <AboutBanner/>
      <VirtualTour/>
      <CapabilitiesSection capabilities={capabilities} />
      <LatestNews latestNewsData={latestNewsData} />
      {/* <JoinUsBanner joinUsBanner={joinUsBanner}/> */}
      <Keywords 
        id={"product-keywords"} 
        keywords={[
          "stem cell indonesia",
          "stem cell regenic",
          "cell therapy indonesia",
          "terapi sel punca indonesia",
          "secretome indonesia",
          "terapi secretome",
          "stem cell kalbe",
          "terapi regeneratif",
          "regenerative medicine indonesia",
          "exosome",
          "iPSC",
          "terapi pengobatan regeneratif"
        ]}
      />
    </Layout>
  )
}

export default IndexPage

export const Head = () => {
  return (
    <SEO
      title={`ReGeniC | Home`}
      description={
        'Discover how ReGeniC is revolutionizing the biopharmaceutical industry. Explore our clinically proven generics and innovative treatments for a healthier future.'
      }
      keywords={[
        "stem cell indonesia",
        "stem cell regenic",
        "cell therapy indonesia",
        "terapi sel punca indonesia",
        "secretome indonesia",
        "terapi secretome",
        "stem cell kalbe",
        "terapi regeneratif",
        "regenerative medicine indonesia",
        "exosome",
        "iPSC",
        "terapi pengobatan regeneratif"
      ]}
    />
  )
}

export const query = graphql`
  query ($language: String!, $locale: String) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    capabilities: allContentfulProductsServices(
      filter: {node_locale: {glob: $locale}}
    ) {
      nodes {
        image {
          gatsbyImageData(width: 400, height:300)
        }
        title
        description {
          description
        }
        slug
      }
    }
    news: allContentfulNewsEvent(
      limit: 6
      filter: { node_locale: { glob: $locale } }
      sort: { order: DESC, fields: publishDate }
    ) {
      nodes {
        id
        image {
          gatsbyImageData(width: 400, height:250)
        }
        slug
        title
        publishDate(formatString: "DD/MM/YYYY")
      }
    }
  }
`
